<template>
	<nav class="breadcrumbs">
		<ul class="breadcrumbs__inner">
			<li
				class="breadcrumbs__item"
				v-for="(item, num) in $route.matched"
				itemscope
				itemtype="http://data-vocabulary.org/Breadcrumb"
				:key="num"
			>
				<router-link
					class="breadcrumbs__link"
					itemprop="title"
					:to="item.path === '' ? '/' : item.path"
				>{{ item.meta.name }}
				</router-link>
				<img
					v-if="num < $route.matched.length-1"
					src="~@/assets/img/icons/breadcrumbs-arrow.svg"
					alt="Arrow"
				>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Breadcrumbs'
}
</script>

<style lang="scss">
.breadcrumbs
{
	margin-bottom: 24px;

	.breadcrumbs__inner
	{
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.breadcrumbs__item
	{
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.breadcrumbs__link
	{
		color: $greyLightText;
		font-family: $mainFontNew;
		line-height: 14px;
		font-size: 12px;
	}
}
</style>